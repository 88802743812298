'use client'

import React from "react";
import * as rdd from 'react-device-detect'
import {AppStoreBadge, AppStoreBadge2} from "@/components/site/AppStore";
import {GooglePlayBadge, GooglePlayBadge2} from "@/components/site/GooglePlay";

export default function GetTheApp() {
    if (rdd.isIOS) {
        return (
            <AppStoreBadge/>
        )
    } else if (rdd.isAndroid) {
        return (
            <GooglePlayBadge/>
        )
    } else {
        const shareURL = '/get-the-app.webp'
        return (
            <div className={'flex flex-row items-center justify-center'}>
                <img alt="QR Code" className={"rounded-[10px]"} height={328} src={shareURL} width={328}/>
            </div>
        )
    }
}

export function GetTheApp2() {
    if (rdd.isIOS || rdd.isAndroid) {
        return (
            <div className={"flex flex-col items-center w-full max-w-[360px] pt-[20px]"}>
                {rdd.isIOS &&
                    <AppStoreBadge2/>
                }
                {rdd.isAndroid &&
                    <GooglePlayBadge2/>
                }
            </div>
            )
    } else {
        const shareURL = '/get-the-app.webp'
        return (
            <div className={"flex flex-col items-center w-full max-w-[370px] p-[35px] border border-brand-border-gray rounded-brand-rounded"}>
                <p className={`home-get-the-app`}>
                    Scan to get the app
                </p>
                <div className={'flex flex-row items-center justify-center'}>
                    <img
                        alt="QR Code"
                        className={"rounded-[10px]"}
                        height={150}
                        src={shareURL}
                        width={150}/>
                </div>
            </div>
        )
    }
}
